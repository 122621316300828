$breakpoints: (
  'xs': 0px,
  'sm': 600px,
  'md': 960px,
  'lg': 1280px,
  'xl': 1920px,
);

@mixin mediaUp($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
