@import 'breakpoints';

$sectionsWidth: (
  'xs': 340px,
  'sm': 340px,
  'md': 340px,
  'lg': 1104px,
  'xl': 1104px,
);

@mixin maxSectionWidth() {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @each $breakpoint, $width in $sectionsWidth {
    @include mediaUp($breakpoint) {
      max-width: $width;
    }
  }
}
