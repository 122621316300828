@import 'colors';
@import 'spacing';
@import 'breakpoints';

.root {
  width: 100%;
  margin: 56px auto 40px auto;
  display: flex;
  flex-flow: column nowrap;
  @include maxSectionWidth();

  @include mediaUp('lg') {
    margin: 56px auto 48px auto;
  }
}
.subtitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: $secondary-text-color;
  text-align: center;
  margin: 0;
}
.brandsList {
  padding: 0;
  width: 100%;
  max-width: 1200px; //1104px;
  list-style-type: none;
  display: flex;
  margin: 32px auto 0 auto;
  flex-flow: row wrap;
  justify-content: space-between;

  @include mediaUp('lg') {
    margin: 64px auto 0 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
.brandListElement {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  margin: 8px auto;

  @include mediaUp('lg') {
    flex-basis: 20%;
    margin: 0 auto;
  }
}

.image {
  display: block;
  max-width: 100%;
}
